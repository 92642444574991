<template>
    <div :class="['collapsible', { 'collapsible-open': isOpen }]">
        <div class="collapsible-header" @click="toggle">
            <div>
                {{ title }}
            </div>
            <div v-if="!isOpen" style="margin-right: 25px;">
                <img src="@/assets/images/chevron-up.png">
            </div>
            <div v-if="isOpen" style="margin-right: 25px;">
                <img src="@/assets/images/chevron-down.png">
            </div>
        </div>
        <div class="collapsible-content" v-if="isOpen">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import '@/assets/font/font.css'

export default {
    name: 'MyCollapsible',
    props: {
        title: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        }
    }
};

</script>

<style scoped>
.collapsible {
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(66, 66, 66, 0.5);
    cursor: pointer;
}

.collapsible-open {
    background-color: rgba(101, 31, 255, 1);
}

.collapsible-header {
    margin: 25px;
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapsible-content {
    margin: 25px;
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>