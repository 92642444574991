<template>
  <div class="landing">
    <img src="@/assets/images/wave1.png" alt="wave1" class="wave1-background">
    <img src="@/assets/images/Parallax-Group.png" alt="parallax-background" class="parallax-background">
    <img src="@/assets/images/Parallax-Footer.png" alt="parallax-background-footer" class="parallax-background-footer">
    <div class="header">
      <img style="margin-left: 30px;" src="@/assets/images/Logo.png" alt="logo">
      <div class="layout">
        <div class="selectable-text">
          <p>About</p>
          <div style="display: flex; " v-show="true">
            <div class="header-dot"></div>
            <div class="header-line"></div>
          </div>
        </div>
        <div class="selectable-text">
          <p>Features</p>
          <div style="display: flex; " v-show="false">
            <div class="header-dot"></div>
            <div class="header-line"></div>
          </div>
        </div>
        <div class="selectable-text">
          <p>Deveplop</p>
          <div style="display: flex; " v-show="false">
            <div class="header-dot"></div>
            <div class="header-line"></div>
          </div>
        </div>
        <div class="selectable-text">
          <p>Commuinty</p>
          <div style="display: flex; " v-show="false">
            <div class="header-dot"></div>
            <div class="header-line"></div>
          </div>
        </div>
      </div>
      <div class="option">
        <button class="login">LOGIN</button>
        <button class="regular">Regular</button>
        <div style="width: 2px;height: 25px;background-color: rgb(255, 255, 255);"></div>
        <button class="setting"></button>
      </div>
    </div>
    <div class="banner">
      <div class="text">
        <h>
          Ultimate Decentralized
          GPU Network for AI
        </h>
        <h2>
          Cost-effective Computing Power for
          AI Training and Inference
        </h2>
        <div class="text-button">
          <button class="get-started">GET STARTED</button>
          <button class="read-documentation" style="margin-left: 50px;">READ DOCUMENTATION</button>
        </div>
      </div>
      <div style="display: flex;  justify-content: center;">
        <div class="pagination">
          <div class="pagination-item">
            <div :class="{ selected: FirstSlide, unselected: !FirstSlide }" @click="changebanner(1)">
              <div class="circle" v-show="FirstSlide"></div>
              First Slide
            </div>
            <h>Interdum et malesuada ac ante…</h>
          </div>
          <div class="vertical-line"></div>
          <div class="pagination-item">
            <div :class="{ selected: SecondSlide, unselected: !SecondSlide }" @click="changebanner(2)">
              <div class="circle" v-show="SecondSlide"></div>
              Read White Paper
            </div>
            <h>Interdum et malesuada ac ante…</h>
          </div>
          <div class="vertical-line"></div>
          <div class="pagination-item">
            <div :class="{ selected: ThirdSlide, unselected: !ThirdSlide }" @click="changebanner(3)">
              <div class="circle" v-show="ThirdSlide"></div>
              Third Slide
            </div>
            <h>Interdum et malesuada ac ante…</h>
          </div>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-title">
        The Power of Global GPU Resources
      </div>
      <div class="about-text">
        We provide secure, cost-effective access to enterprise grade GPUs
        <br>
        around the world
      </div>
      <div class="about-rectangle">
        <div style="display: flex;flex-direction: column;align-items: center;gap: 25px;">
          <div class="rectangle-s">
            <div class="rectangle-img">
              <img src="@/assets/images/Picture-cpu.png">
            </div>
            <div class="rectangle-text">
              10,000+ GPU on demand
            </div>
          </div>
          <div class="rectangle-s">
            <div class="rectangle-img">
              <img src="@/assets/images/Picture-cheaper.png">
            </div>
            <div class="rectangle-text">
              Over 40% cheaper
            </div>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;gap: 25px;">
          <div class="rectangle-b">
            <div class="rectangle-img">
              <img src="@/assets\images\Picture-time.png">
            </div>
            <div class="rectangle-text">
              99.99% uptime
            </div>
          </div>
          <div class="rectangle-b">
            <div class="rectangle-img">
              <img src="@/assets/images/Picture-world.png">
            </div>
            <div class="rectangle-text">
              10+ Countries supported around the world
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="features">
      <div class="features-title">
        Customers: Unleash your AI potential
        <br>
        Businesses: Optimze your GPU cost
      </div>
      <div class="features-dot-line">
        <div class="features-dot"></div>
        <div class="features-line"></div>
      </div>
      <div class="features-text">
        The Power of Global GPU Resources with a Single Click
      </div>
      <div class="features-content">
        <div class="features-picture">
          <img src="@/assets/images/Asset-1.png">
        </div>
        <div style="display: flex;">
          <div class="bule-circle"></div>
          <div style="display: flex;flex-direction: column;gap: 15px;margin-left: 15px;">
            <div class="features-text-title-bule">
              Decentralized
              <br>
              clusters
            </div>
            <div class="features-text">
              Deploy On A Decentralized Physical
              <br>
              Infrastructure Network. DePIN*
            </div>
          </div>
        </div>
      </div>
      <div class="features-content">
        <div style="display: flex;">
          <div class="pink-circle"></div>
          <div style="display: flex;flex-direction: column;gap: 15px;margin-left: 15px;">
            <div class="features-text-title-pink">
              Streamlined Cluster
              <br>
              Deployment
            </div>
            <div class="features-text" style="text-align: right;">
              Frictionless Cluster Deployment For
              <br>
              Your ML Workloads
            </div>
          </div>
        </div>
        <div class="features-picture">
          <img src="@/assets/images/Asset-2.png">
        </div>
      </div>
      <div class="features-content">
        <div class="features-picture" style="width: 362px; height: 529px;">
          <img src="@/assets/images/Asset-3.png">
        </div>
        <div style="display: flex;">
          <div class="bule-circle"></div>
          <div style="display: flex;flex-direction: column;gap: 15px;margin-left: 15px;">
            <div class="features-text-title-bule">
              Out of the box
              <br>
              deployment
            </div>
            <div class="features-text">
              Pre Configured Deployment Of
              <br>
              Your Favorite ML Stacks
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="develop">
      <div class="develop-title">
        Cutting-edge DePIN technolgy
      </div>
      <div class="features-dot-line">
        <div class="features-dot"></div>
        <div class="features-line"></div>
      </div>
      <div class="depin-technology" style="position: relative;">
        <div class="develop-technology">
          <div style="position: absolute; z-index: 1;">
            <img src="@/assets/images/Oval-1.png">
          </div>
          <img src="@/assets/images/transaction1.png" style="z-index: 2;">
          <div class="title">
            Functional
            <br>
            Computing
          </div>
        </div>
        <div class="features-dot-line">
          <div class="features-dot"
            style="background: radial-gradient(circle,rgba(209, 196, 233, 1) 0%,rgba(101, 31, 255, 1) 100%);"></div>
          <div class="features-line"
            style=" background: linear-gradient(to bottom,rgba(209, 196, 233, 1) 0%,rgba(101, 31, 255, 1)100%);"></div>
        </div>
        <div class="develop-technology">
          <div style="position: absolute; z-index: 1;">
            <img src="@/assets/images/Oval-2.png">
          </div>
          <img src="@/assets/images/transaction2.png" style="z-index: 2;">
          <div class="title">
            Decentralized
            <br>
            Security
          </div>
        </div>
        <div class="features-dot-line">
          <div class="features-dot"
            style="background: radial-gradient(circle,rgba(0, 229, 255, 1) 0%,rgba(0, 96, 100, 1) 100%);"></div>
          <div class="features-line"
            style=" background: linear-gradient(to bottom,rgba(0, 229, 255, 1) 0%,rgba(0, 96, 100, 1)100%);"></div>
        </div>
        <div class="develop-technology">
          <div style="position: absolute; z-index: 2;">
            <img src="@/assets/images/Oval-3.png">
          </div>
          <img src="@/assets/images/transaction3.png" style="z-index: 2;">
          <div class="title">
            Start building
            <br>
          </div>
        </div>
      </div>
      <div class="technology-text">
        <div class="text" style="width: 350px;">
          All AI apps are deployed through our functional computing system
        </div>
        <div class="text" style="width: 450px;">
          Onblox.ai network is secured by decentralized competitions and zero knowledge encryptions, making data
          virtually impenetrable.
        </div>
        <div class="text" style="width: 350px;">
          AIGC, decentralized NAS, remote gaming and innovative new apps are constantly being updated.
        </div>
      </div>
      <div class="features-click">
        <div class="develop-title">
          AIGC in one click
        </div>
        <div class="click-text" style="text-align: center; width: 650px;">
          100+ AI Models and Apps Graphics, Audio, Video, LLM, Developer Tools.
        </div>
        <div>
          <button class="more">
            EXPLORE MORE
          </button>
          <img src="@/assets/images/arrow.png">
        </div>
        <div class="click-content">
          <div class="item">
            <img src="@/assets/images/AIGC3.png">
            <div class="click-text" style="align-items: left;">
              GPU: RTX4090, RTX4060TI
            </div>
          </div>
          <div class="item">
            <img src="@/assets/images/AIGC4.png">
            <div class="click-text" style="align-items: left;">
              GPU: TRX4090, RTX3090, RTX4060TI, RTX3080
            </div>
          </div>
          <div class="item">
            <img src="@/assets/images/AIGC2.png">
            <div class="click-text" style="align-items: left;">
              GPU: RTX4090, RTX3090
            </div>
          </div>
          <div class="item">
            <img src="@/assets/images/AIGC1.png">
            <div class="click-text" style="align-items: left;">
              GPU: RTX4090, RTX4060TI
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="community">
      <div class="community-left">
        <div class="community-title" style="z-index: 2;">
          Frequently
          <br>asked
          <br>
          questions
        </div>
        <div style="position: relative;">
          <div style="position:absolute;left:0;top:-25px;z-index:2;">
            <div class="features-dot-line">
              <div class="features-dot"></div>
              <div class="features-line"></div>
            </div>
          </div>
          <div style="position:absolute;left:-130px;top:-100px;z-index:1;">
            <img src="@/assets/images/Robot.png">
          </div>
          <div style="position:absolute;left:80px;top:320px;z-index:2;">
            <button class="community-button">
              <div style="height: 35px;">
                Visit FAQ Center
              </div>
              <div style="height: 35px;">
                <img src="@/assets/images/black-arrow.png">
              </div>
            </button>
          </div>
        </div>
      </div>
      <div class="community-right">
        <my-collapsible title="What is Onblox?">
          Onblox is Ultimate Decentrazlied GPU network for AI
        </my-collapsible>
        <my-collapsible title="In mi nulla, fringilla vestibulum?">
          collapsible2
        </my-collapsible>
        <my-collapsible title="In mi nulla, fringilla vestibulum?">
          collapsible3
        </my-collapsible>
        <my-collapsible title="In mi nulla, fringilla vestibulum?">
          collapsible4
        </my-collapsible>
        <my-collapsible title="In mi nulla, fringilla vestibulum?">
          collapsible5
        </my-collapsible>
      </div>
    </div>
    <div class="community-under">
      <div class="community-under-background" style="position:relative;">
        <div style="position: absolute;top:312px;">
          <img src="@/assets/images/JoinCommunity.png">
        </div>
        <div class="community-under-content" style="position: absolute;top:10px;">
          <div class="community-under-title">
            JOIN THE COMMUNITY
          </div>
          <div class="community-under-text">
            Please do join the community to get updates and/or contribute as a developer or miner.
          </div>
          <button class="community-under-button">
            JOIN NOW
          </button>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-logo">
        <div>
          <img src="@/assets/images/Logo.png">
        </div>
        <div class="footer-text">
          Ultimate Decentralized GPU Network for AI
        </div>
        <div class="footer-text" style="margin-top: 10px;">
          Onblox AI 2024
        </div>
      </div>
      <div class="footer-company">
        <div class="footer-company-title">
          COMPANY
        </div>
        <div class="footer-text">
          About us
        </div>
        <div class="footer-text">
          Team and İnvestor
        </div>
        <div class="footer-text">
          FAQs
        </div>
        <div class="footer-text">
          Media Kit
        </div>
        <div class="footer-text">
          Contact us
        </div>
      </div>
      <div class="footer-menu">
        <div class="footer-menu-title">
          <img src="@/assets/images/footer-1.png" alt="footer-1" style="cursor: pointer;">
          <img src="@/assets/images/footer-2.png" alt="footer-2" style="cursor: pointer;">
          <img src="@/assets/images/footer-3.png" alt="footer-3" style="cursor: pointer;">
          <img src="@/assets/images/footer-4.png" alt="footer-4" style="cursor: pointer;">
        </div>
        <div class="footer-menu-language">
          <img src="@/assets/images/Language.png" alt="language" style="height: 20px; width: 20px;">
          <div class="language-text">
            English - En
          </div>
          <img src="@/assets/images/chevron-down-footer.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/font/font.css'
import myCollapsible from './components/MyCollapsible.vue';
export default {
  name: 'App',
  components: {
    myCollapsible,
  },
  data() {
    return {
      FirstSlide: true,
      SecondSlide: false,
      ThirdSlide: false,
    }
  },
  methods: {
    changebanner(c) {
      this.FirstSlide = c == 1;
      this.SecondSlide = c == 2;
      this.ThirdSlide = c == 3;
    },
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
}

.landing {
  width: 100%;
  height: 9104px;
  background: rgba(49, 27, 146, 1);
  z-index: 2;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
  overflow: hidden;

  .wave1-background {
    position: absolute;
    width: 50%;
    height: 832px;
    left: 50%;
    top: 30px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  .parallax-background {
    position: absolute;
    width: 100%;
    height: 7250px;
    left: 0px;
    top: 750px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }

  .parallax-background-footer {
    position: absolute;
    width: 100%;
    height: 274px;
    top: 8830px;
    left: 0px;
    z-index: 1;
    overflow: hidden;
    pointer-events: none;
  }
}

.header {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;

  .layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;

    .selectable-text {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-direction: column;
      gap: 5px;
      width: 150px;
      height: 35px;
      top: 10px;
      opacity: 0px;
      font-family: 'Montserrat';
      font-size: 18px;
      color: #ffffff;
      font-weight: 400;
      line-height: 21.94px;
      text-align: left;

      p {
        margin: 0;
        padding: 0;
      }

      .header-dot {
        width: 6px;
        height: 6px;
        background: radial-gradient(circle,
            rgba(178, 235, 242, 1) 0%,
            rgba(209, 196, 233, 1) 50%,
            rgba(248, 187, 208, 1) 100%);
        border-radius: 50%;
        display: inline-block;
      }

      .header-line {
        width: 23px;
        height: 6px;
        margin-left: 6px;
        background: linear-gradient(to bottom,
            rgba(178, 235, 242, 1) 0%,
            rgba(209, 196, 233, 1)100%);
        border-radius: 3px;
      }
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15%;
    gap: 20px;

    .login {
      background-color: transparent;
      color: #FFFFFF;
      font-size: 18px;
      font-family: 'Montserrat';
      font-weight: 500;
      line-height: 19.5px;
      text-align: left;
      cursor: pointer;
      border: none;
    }

    .regular {
      width: 114px;
      height: 39px;
      background-color: rgba(209, 196, 233, 1);
      color: rgba(101, 31, 255, 1);
      font-family: 'Montserrat';
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      border-radius: 27px;
      border: none;
    }

    .setting {
      width: 29px;
      height: 29px;
      background-image: url('@/assets/images/android-settings.png');
      background-size: cover;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
}

.banner {
  width: 100%;
  z-index: 2;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 740px;
    margin-top: 115px;
    margin-left: 6%;

    h {
      font-family: 'Tektur';
      color: #FFFFFF;
      font-size: 64px;
      font-weight: 800;
      line-height: 83.2px;
      text-align: left;
    }

    h2 {
      font-family: 'Montserrat';
      color: #FFFFFF;
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      text-align: left;
    }

    .text-button {
      display: flex;
      width: 100%;

      .get-started {
        width: 236px;
        height: 54px;
        border-radius: 27px;
        background-color: rgba(55, 236, 145, 1);
        font-family: 'Montserrat';
        color: rgba(0, 0, 0, 1);
        font-size: 16px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: center;
        cursor: pointer;
      }

      .read-documentation {
        width: 236px;
        height: 54px;
        border-radius: 27px;
        border: 1px solid rgba(255, 255, 255, 1);
        background-color: transparent;
        font-family: 'Montserrat';
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: center;
        cursor: pointer;
      }
    }


  }

  .pagination {
    display: flex;
    align-items: center;
    width: 1044px;
    height: 73px;
    margin-top: 150px;
    flex-direction: row;
    justify-content: space-between;

    .pagination-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      cursor: pointer;

      .circle {
        width: 20px;
        height: 20px;
        background-color: rgba(101, 31, 255, 1);
        border-radius: 50%;
        display: inline-block;
      }

      .selected {
        margin: 0;
        padding: 0;
        color: rgba(101, 31, 255, 1);
        font-family: 'Montserrat';
        font-size: 28px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }

      .unselected {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-size: 28px;
        font-weight: 400;
        line-height: 24px;
      }

      h {
        margin: 0;
        padding: 0;
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
    }

    .vertical-line {
      width: 1px;
      height: 63px;
      background-color: rgb(255, 255, 255);
    }
  }
}

.about {
  z-index: 2;
  width: 100%;
  height: 911px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;

  .about-title {
    font-family: Tektur;
    font-size: 65px;
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    line-height: 83.2px;
    text-align: left;
  }

  .about-text {
    margin-top: 10px;
    font-family: Montserrat;
    font-size: 33px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }

  .about-rectangle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 70px;

    .rectangle-text {
      font-family: Montserrat;
      font-size: 32px;
      font-weight: 400;
      line-height: 48px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }

    .rectangle-img {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 40px;
    }

    .rectangle-s {
      display: flex;
      align-items: center;
      gap: 35px;
      width: 460px;
      height: 168px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.12);
    }

    .rectangle-b {
      display: flex;
      align-items: center;
      gap: 35px;
      width: 630px;
      height: 168px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
}

.features {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .features-title {
    margin-top: 120px;
    font-family: 'Montserrat';
    font-size: 64px;
    font-weight: 800;
    line-height: 72px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }


  .features-text {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-top: 50px;
  }

  .features-content {
    margin-top: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 110px;

    .features-picture {
      width: 405px;
      height: 343px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    .features-picture img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .bule-circle {
      width: 18px;
      height: 18px;
      background: radial-gradient(circle,
          rgba(132, 255, 255, 1) 0%,
          rgba(0, 96, 100, 1) 100%);
      border-radius: 50%;
    }

    .pink-circle {
      width: 18px;
      height: 18px;
      background: radial-gradient(circle,
          rgba(101, 31, 255, 1) 0%,
          rgba(209, 196, 233, 1) 100%);
      border-radius: 50%;
    }

    .features-text-title-bule {
      font-family: 'Montserrat';
      font-size: 48px;
      font-weight: 600;
      line-height: 58.51px;
      text-align: left;
      background: linear-gradient(90deg,
          rgba(132, 255, 255, 1) 0%,
          rgba(0, 96, 100, 1) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }

    .features-text-title-pink {
      font-family: 'Montserrat';
      font-size: 48px;
      font-weight: 600;
      line-height: 58.51px;
      text-align: right;
      background: linear-gradient(90deg,
          rgba(101, 31, 255, 1) 0%,
          rgba(209, 196, 233, 1)100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
}

.features-dot-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;

  .features-dot {
    width: 12px;
    height: 12px;
    background: radial-gradient(circle,
        rgba(178, 235, 242, 1) 0%,
        rgba(209, 196, 233, 1) 50%,
        rgba(248, 187, 208, 1) 100%);
    border-radius: 50%;
    display: inline-block;
  }

  .features-line {
    width: 71.5px;
    height: 12px;
    margin-left: 10px;
    background: linear-gradient(to bottom,
        rgba(178, 235, 242, 1) 0%,
        rgba(209, 196, 233, 1) 50%,
        rgba(248, 187, 208, 1) 100%);
    border-radius: 6px;
  }

}

.develop {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 180px;

  .develop-title {
    font-family: 'Montserrat';
    font-size: 64px;
    font-weight: 800;
    line-height: 72px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }

  .depin-technology {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 70px;
    margin-top: 80px;
  }

  .develop-technology {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .title {
      font-family: 'Montserrat';
      font-size: 36px;
      font-weight: 800;
      line-height: 43.88px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      height: 88px;
      width: 248px;
    }
  }

  .technology-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-top: 40px;
    gap: 70px;

    .text {
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 400;
      line-height: 29.26px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }

  .features-click {
    display: flex;
    margin-top: 200px;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    .click-text {
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 400;
      line-height: 29.26px;
      color: rgba(255, 255, 255, 1);
    }

    .more {
      cursor: pointer;
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 700;
      line-height: 29.26px;
      text-align: center;
      color: rgba(55, 236, 145, 1);
      background: transparent;
      border: 0;
    }

    .click-content {
      margin-top: 75px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 70px;

      .item {
        width: 540px;
        height: 380px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        gap: 15px;
      }

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}

.community {
  z-index: 2;
  width: 100%;
  display: flex;
  margin-top: 300px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .community-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;

    .community-title {
      font-family: 'Montserrat';
      font-size: 64px;
      font-weight: 800;
      line-height: 72px;
      text-align: left;
      color: rgba(255, 255, 255, 1);
    }

    .community-button {
      width: 291px;
      height: 112px;
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 800;
      line-height: 29.26px;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-radius: 27px;
      background: linear-gradient(to bottom, rgba(0, 229, 255, 1) 75%, rgba(255, 255, 255, 1));
      cursor: pointer;
    }
  }

  .community-right {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 50px;
  }
}

.community-under {
  margin-top: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .community-under-background {
    width: 1245px;
    height: 612px;
    background: linear-gradient(to bottom,
        rgba(132, 255, 255, 1),
        rgba(211, 196, 238, 1),
        rgba(248, 187, 208, 1));
    border-radius: 60px;
    box-shadow: 0 -80px 80px 0 rgba(132, 255, 255, 0.5);
  }

  .community-under-content {
    width: 1245px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    .community-under-title {
      font-family: 'Montserrat';
      font-size: 64px;
      font-weight: 800;
      line-height: 96px;
      text-align: center;
      color: rgba(0, 0, 0, 1);
    }

    .community-under-text {
      font-family: 'Montserrat';
      font-size: 32px;
      font-weight: 400;
      line-height: 40px;
      text-align: center;
      color: rgba(0, 0, 0, 1);
    }

    .community-under-button {
      cursor: pointer;
      width: 541px;
      height: 69px;
      background: rgba(101, 31, 255, 1);
      border-radius: 27px;
      border: none;
      font-family: 'Montserrat';
      font-size: 24px;
      font-weight: 600;
      line-height: 29.26px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.footer {
  z-index: 2;
  width: 100%;
  margin-top: 430px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;

  .footer-text {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }

  .footer-logo {
    width: 285px;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }

  .footer-company {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    .footer-company-title {
      font-family: 'Montserrat';
      font-size: 18px;
      font-weight: 700;
      line-height: 21.94px;
      text-align: left;
      color: rgba(209, 196, 233, 1);
    }
  }

  .footer-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-left: 400px;
    margin-top: 30px;

    .footer-menu-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;

      img {
        width: 37.5px;
        height: 37.5px;
      }
    }

    .footer-menu-language {
      width: 211px;
      height: 38.5px;
      background-color: rgba(66, 66, 66, 1);
      border: 1px solid rgba(103, 58, 183, 1);
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      cursor: pointer;

      .language-text {
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: 400;
        line-height: 21.94px;
        text-align: left;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

}
</style>
